let config;

if (process.env.NODE_ENV === "production"){
    config = {
        loginUrl : "https://preorder.b2b.schuberth.com",
        cookieDomain: "preorder.b2b.schuberth.com",
        alwaysShowNavbar: false,
        APIURL: "https://prod.api.schuberth.info",
        isDevSystem: false
    }
}
/*if (process.env.NODE_ENV === "production"){
    config = {
        loginUrl : "http://svxdocker-dev:50802",
        cookieDomain: "svxdocker-dev:50802",
        alwaysShowNavbar: false,
        APIURL: "http://dev.api.schuberth.local",
        isDevSystem: true
    }
}*/
else {
    config = {
        loginUrl : "http://localhost:8080",
        cookieDomain: "localhost",
        alwaysShowNavbar: false,
        APIURL: "http://dev.api.schuberth.local",
        isDevSystem: true
    }
}


export { config }