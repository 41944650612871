import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import {store} from './store/store.js';
import {config} from './config.js';

Vue.config.productionTip = false
Vue.prototype.$config = config;

new Vue({
  config: config,
  store: store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
