
import Vue from "vue";
import VueRouter from "vue-router";
import { store } from '../store/store.js'; // eslint-disable-line no-unused-vars
import {config} from '../config.js';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "PREORDER",
    meta: {
      requiresUserAuth: true,
      title: 'SCHUBERTH PREORDER'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "preorder" */ "../views/preorder.vue"),
  },
  {
    path: "/checkout",
    name: "CHECKOUT",
    meta: {
      requiresUserAuth: true,
      title: 'SCHUBERTH PREORDER - CHECKOUT'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
  },
  {
    path: "/csv",
    name: "CSV IMPORT",
    meta: {
      requiresUserAuth: true,
      title: 'SCHUBERTH PREORDER - CSV IMPORT'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "csv" */ "../views/csv.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: 'SCHUBERTH PREORDER - Login'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

/*
//redirect to login page if not logged in and trying to access protected resource
router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  const pubPages = ['/login'];
  const authRequired = !pubPages.includes(to.path);
  const loggedIn = localStorage.getItem('shjwt');

  if(authRequired && !loggedIn){
      return next('/login');
  }

  next();
})
*/

//ENABLE THIS IF LOGIN IS REQUIRED!

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresUserAuth)) {
    //if there is no token or expired, push without error message
    //console.log(store.getters.tokenNotGivenOrExpired())
    if (store.getters.tokenNotGivenOrExpired()) {
      store.dispatch("logout");
      //push to global login-site, attach original url
      window.location.href = `${config.loginUrl}/login?returnUrl=${encodeURIComponent(window.location.origin)}${encodeURIComponent(to.fullPath)}`; 
      //next('/login'); 
      next(false);
      return;
    }

    if (store.getters.isLoggedInAsUser || store.getters.isLoggedInAsAdmin) {
      next()
      return
    }
    //only show error and nothing more
    //next('/login')
    store.commit('showError',{ message:'You are not allowed to access the requested Resource' } );
    //return;
  }
  /*else if(to.matched.some(record => record.meta.requiresAdminAuth)){
    //if there is no token or expired, push without error message
    if(store.getters.tokenNotGivenOrExpired){ next('/login'); return}

    if (store.getters.isLoggedInAsAdmin) {
      next()
      return
    }
    next('/login')
    throw new Error('You are not allowed to access the requested Resource');
    //store.commit('showError','You are not allowed to access the requested Resource');
    //return;
  }*/ else {
    next();
  }

})

router.onError((err) => { store.dispatch('showError', err.message); })

export default router;
