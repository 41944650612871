<template>
  <nav :class="{ alwaysVisible: this.$config.alwaysShowNavbar }">
    <v-app-bar flat dense app>
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <router-link to="/" style="text-decoration: none"
        ><v-toolbar-title class="text-uppercase mr-10">
          <span class="schuberthMain--text font-weight-light">SCHUBERTH</span>
          <span class="schuberthMain--text">PREORDER</span>
        </v-toolbar-title>
      </router-link>

      <v-spacer></v-spacer>

      <v-btn text class="grey--text" @click="logout">
        <v-icon>mdi-logout</v-icon>LOGOUT
      </v-btn>
      <v-btn text class="grey--text" @click="showSettings()">
        <v-icon class="mr-1">mdi-cog-outline</v-icon>{{translate("getNavBarSettingsText")}}
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      class="schuberthCarbon"
      :src="'./nav/' + randomPic"
    >
      <v-layout column align-center>
        <v-flex class="mt-5">
          <a href="https://schuberth.com">
            <v-avatar size="100">
              <img :src="'./lazy_head_.png'" />
            </v-avatar>
          </a>
        </v-flex>
        <v-flex>
          <p class="white--text subheading mt-1">
            {{ userName }}
            <v-icon v-if="isLoggedInAsAdmin" color="schuberthMain">mdi-racing-helmet</v-icon>
          </p>
        </v-flex>
        <!--<v-flex @click="isInternal = !isInternal" style="cursor: pointer;">
          <span class="captionSmall text-uppercase mt-1" style="border-radius: 25px; background-color: rgba(34, 34, 34, 0.22);">
            <span :class="isInternal ? 'grey--text text--lighten-2' : 'font-weight-bold white--text'">Globale-Links</span>
            <v-icon medium color="white">mdi-swap-horizontal</v-icon>
            <span :class="isInternal ? 'font-weight-bold white--text' : 'grey--text text--lighten-2'">Internal-Links</span>
          </span>
        </v-flex>-->
      </v-layout>
      <v-list dark>
        <v-list-item
          v-for="link in linkList"
          :key="link.text"
          class="my-1 text-right list-item"
          :disabled="checkPermission(link.scope)"
          :href=" isInternal ? (link.isExternal ? link.route : null) : link.route"
          :target="isInternal ? (link.isExternal ? '_blank' : null) : '_blank'"
          :rel="isInternal ? (link.isExternal ? 'noopener noreferrer' : null) : 'noopener noreferrer'"
          router
          :to="isInternal ? (link.isExternal ? null : link.route) : null"
        >
          <v-list-item-action>
            <v-icon color="white">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

   <v-dialog :value="showSettingsDialog" width="400px" persistent scrollable>
    <v-card>
      <v-card-title class="text-h5 schuberthMain white--text elevation-1">
        Settings 
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text justify="center">
        <v-subheader class="ml-n4">Language</v-subheader>
        <v-combobox
          color="schuberthMain"
          persistent-hint
          small-chips
          v-model="selectedLanguage"
          :items="availableLanguages"
          label="Select your language"
        >
            <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  :key="item.lang"
                  v-bind="attrs"
                  @click="select"
                  :input-value="selected"
                >
                    <v-avatar left>
                      <v-img :src="`/flags/${item.avatar}`"></v-img>
                    </v-avatar>
                    {{item.text}}
                </v-chip>
            </template>
          </v-combobox>
        <v-divider></v-divider>
     
      </v-card-text>
      <v-card-actions>
        <v-btn icon color="success" @click="hideSettings()">
          <v-icon> mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { translationService } from "@/services/translationService.js";

export default {
  name: "Navbar",
  data: () => ({
    drawer: false,
    isInternal: true,
    internalLinks: [
      //{icon: 'mdi-newspaper-variant-outline', text: 'News', route :'/'},
      {
        icon: "mdi-store-24-hour",
        text: "Preorder-Shop",
        route: "/",
        isExternal: false,
        scope: []
      },
      {
        icon: "mdi-file-delimited",
        text: "CSV-Import",
        route: "/csv",
        isExternal: false,
        scope: []
      },
      //{icon: 'mdi-hammer-screwdriver', text: 'Administration', route: '/'}
    ],
    showSettingsDialog: false,
    selectedLanguage: null,
  }),
  computed: {
    randomPic: function () {
      //return Math.floor(Math.random() * 13 + 1) + ".jpg";
      return "c5.png";
    },
    ...mapGetters(["userName", "links", "decodedJwt", "UILanguage", "isLoggedInAsAdmin"]),
    ...mapState(["availableLanguages"]),
    linkList: function () {
      return this.isInternal ? this.internalLinks : this.links;
    },
  },
  methods: {
    translate: function(field){
      return translationService.translate(field);
    },
    logout: async function () {
      await this.$store.dispatch("logout");
      window.location.href = this.$config.loginUrl;
      return;
    },
    checkPermission: function (scope) {
      return scope.length == 0
        ? false
        : this.decodedJwt !== null
        ? !this.decodedJwt.user.scopes.some(item => scope.includes(item))
        : true;
    },
    showSettings: function(){
      //get currentLanguage from store and set it
      this.selectedLanguage = this.UILanguage;
      this.showSettingsDialog = true;
    },
    hideSettings: async function(){
      //set language to store
      await this.$store.dispatch("setUILanguage", this.selectedLanguage);
      this.showSettingsDialog = false;
    }
  },
  created: async function () {
    //await this.$store.dispatch("getNavbar");
  },
};
</script>

<style>

.alwaysVisible {
  z-index: 999;
}
.list-item {
  background-color: rgba(34, 34, 34, 0.8);
}
.v-list-item--disabled {
  background-color: rgba(193, 51, 51, 0.23);
}
</style>