<template>
  <div class="text-center">
    <v-snackbar v-model="showError" multi-line top color="#FF4C4C" timeout="-1">
      <!--:timeout="timeout"-->
      {{ error.message | shorten }}
      <v-btn dark text @click="showInfo()"> More Info </v-btn>
      <v-btn dark text @click="hideError()">Close </v-btn>
    </v-snackbar>
    <!--infoDialog-->
    <v-dialog v-model="infoDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5 schuberthMain white--text elevation-1">
          Error Information
        </v-card-title>
        <v-card-text justify="center">
          <v-row class="my-2">
            <v-col cols="12">
              <pre>{{ JSON.stringify(error) | pretty }}</pre>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="schuberthMain" class="white--text" @click="close()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ErrorNotifier",
  //props:['showError', 'message'],
  data: () => ({
    //timeout: 20000,
    showDialog: false,
  }),
  computed: {
    ...mapState(["showError", "error"]),
    infoDialog:{
      get: function(){
        return this.showError && this.showDialog;
      },
      set: function(value){
        this.showDialog = value;
      }
    }
  },
  methods: {
    ...mapActions(["hideError"]),
    showInfo: function () {
      this.infoDialog = true;
    },
    close: async function () {
      await this.hideError();
      this.infoDialog = false;
    },
  },
  filters: {
    pretty: function (value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
    shorten: function (value){
      return value && value.length > 60 ? `${value.substr(0,60)}...` : value;
    }
  },
};
</script>

<style scoped>
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>
