import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

const options = {
  iconfont: 'md',
  theme: {
    themes: {
      light: {
        schuberthMain: '#ee7100',
        schuberthAluminium: '#eeeeee',
        schuberthSteel: '#768086',
        schuberthCarbon: '#222222',
        schuberthRed: '#91281e',
        schuberthCream: '#f0d2b4'
      }
    }
  }
}
/*
const options = {
  iconfont: 'md',
  theme: {
    primary: '#ee7100',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#ff4444',
    info: '#33b5e5',
    success: '#00C851',
    warning: '#ffbb33'
  }
};*/

Vue.use(Vuetify, options);

export default new Vuetify(options);
