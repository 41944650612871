import { store } from '../store/store.js';
import translations from "../translations.json";

export const translationService =  {
    translate
}

function translate(field){
    //find field in translations, if not found, return NO_TRANSLATION_FOUND
    const element = translations[field];
    if(!element){
      return "NO_TRANSLATION_FOUND";
    }
    //if no translation then fallback to englisch for these specific thing?
    return store.getters.UILanguage ? (element[store.getters.UILanguage.id] || element.E) : element.E
    //return element[store.getters.UILanguage.id] || "NO_TRANSLATION_FOR_LANG_ID"
}