<template>
  <v-dialog :value="showDialog" max-width="800px" persistent scrollable>
    <v-card>
      <v-card-title class="text-h5 schuberthMain white--text elevation-1">
        <v-icon color="white" class="ml-n4 mr-2">mdi-account-convert</v-icon>
        {{translate("getDeliverAdressSelectionHeader")}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text justify="center">
        <v-row no-gutters class="mb-n3 mt-2">
          <v-col cols="12">
            <v-text-field 
                label="Search address" 
                v-model="search"
                dense
                single-line
                placeholder="Search address"
                outlined
                clearable
                color="schuberthMain">
              </v-text-field>
        </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-list dense class="mt-1">
              <v-list-item-group v-model="selectedAdressId" mandatory>
                <v-list-item
                  v-for="availableAdress of filteredCustomerAdress"
                  :key="availableAdress.addressid"
                  @dblclick.native="setAdress()"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-account </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        availableAdress.name.name1
                      }} {{ availableAdress.name.name2}} {{ availableAdress.name.name3}}
                      ({{ availableAdress.email }})</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ availableAdress.street}} {{ availableAdress.number}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ availableAdress.country}}-{{ availableAdress.postcode}} {{ availableAdress.city}}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn icon color="success" @click="setAdress()">
          <v-icon> mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { translationService } from "@/services/translationService.js";

export default {
  name: "DeliveryAdressSelection",

  components: {},

  data: () => ({
    search: '',
    selectedAdressId: null,
  }),
  computed: {
    ...mapState(["customer","deliveryAdress", "portfolio", "partData"]),
    ...mapGetters(["isLoggedInAsUser"]),
    showDialog: {
      get() {
        //if only one deliveryAdress, set this
         //if only one customer, set him
        if(this.customer.address.filter(x=>x.address_type == "customer" || x.address_type == "delivery").length == 1){
            this.$store.dispatch('setDeliveryAdress',this.customer.address[0]);
        }
        return (
          this.isLoggedInAsUser &&
          this.customer &&
          !this.deliveryAdress &&
          this.portfolio &&
          this.partData
        );
      },
    },
    selectedAdress: {
      get() {
        return this.customer.address.filter(x => x.address_type == 'customer' || x.address_type == 'delivery')[this.selectedAdressId]
      }
    },
    filteredCustomerAdress: function(){
      if(!this.search){ return this.customer.address.filter(x => x.address_type == 'customer' || x.address_type == 'delivery'); }

      //search or almost everything
      return this.customer.address.filter(x => x.address_type == 'customer' || x.address_type == 'delivery').filter(x => 
      x.city.toLowerCase().includes(this.search.toLowerCase()) ||
      x.country.toLowerCase().includes(this.search.toLowerCase()) ||
      x.email.toLowerCase().includes(this.search.toLowerCase()) ||
      x.number.toLowerCase().includes(this.search.toLowerCase()) ||
      x.postcode.toLowerCase().includes(this.search.toLowerCase()) ||
      x.street.toLowerCase().includes(this.search.toLowerCase()) ||
      x.name.name1.toLowerCase().includes(this.search.toLowerCase()) ||
      x.name.name2.toLowerCase().includes(this.search.toLowerCase()) ||
      x.name.name3.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
     translate: function(field){
      return translationService.translate(field);
    },
    setAdress: async function () {
      await this.$store.dispatch('setDeliveryAdress',this.selectedAdress)
      this.search = '';
    },
  },
  filters: {
    utf8Encode(value){
      console.log(value, '--' ,decodeURIComponent(escape(value)), '--' ,unescape(encodeURIComponent(value)))
      return decodeURIComponent(escape(value))
    }
  },
  updated: async function () {},
  watch: {},
};
</script>

<style>
.captionSmall {
  font-size: 0.625rem !important;
  letter-spacing: 0.1666666667em !important;
}
</style>