<template>
<div>
  <v-dialog :value="showDialog" width="600px" persistent scrollable>
    <v-card>
      <v-card-title class="text-h5 schuberthMain white--text elevation-1">
        <v-icon color="white" class="ml-n4 mr-2">mdi-account-convert</v-icon>
        {{translate("getCustomerSelectionHeaderText")}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text justify="center">
        <v-row no-gutters class="mb-n3 mt-2">
          <v-col :cols="isLoggedInAsAdmin ? '8':'12'">
            <v-text-field 
                label="Search customer" 
                v-model="search"
                dense
                single-line
                placeholder="Search customer"
                outlined
                clearable
                color="schuberthMain"
                @keyup.enter.native="loadCustomer()">
              </v-text-field>
        </v-col>
        <v-col cols="4" v-if="isLoggedInAsAdmin">
          <v-btn
              color="schuberthMain"
              class="white--text ml-6"
              @click="loadCustomer()"
              >Load Customer</v-btn>
        </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-list dense>
              <v-list-item-group v-model="selectedCustomerId" mandatory>
                <v-list-item
                  v-for="(availableCustomer, index) in filteredAvailableCustomers"
                  :key="`${availableCustomer.customerid}-${index}`"
                  @dblclick.native="setCustomer()"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-account </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        availableCustomer.address.find(
                          (x) => x.address_type == "customer"
                        ).name.name1
                      }}
                      ({{ availableCustomer.customerid }})</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{
                        availableCustomer.address.find(
                          (x) => x.address_type == "customer"
                        ).name.name2 != ""
                          ? availableCustomer.address.find(
                              (x) => x.address_type == "customer"
                            ).name.name2 + " - "
                          : ""
                      }}
                      {{
                        availableCustomer.address.find(
                          (x) => x.address_type == "customer"
                        ).city
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{
                        availableCustomer.address.find(
                          (x) => x.address_type == "customer"
                        ).email
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn icon color="success" @click="setCustomer()">
          <v-icon> mdi-check </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showLoadedCartDialog" max-width="600px" persistent scrollable>
    <v-card>
      <v-card-title class="text-h5 schuberthMain white--text elevation-1">
        <v-icon color="white" class="ml-n4 mr-2">mdi-cart-outline</v-icon>
          {{translate("getLoadedCartHeader")}}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text justify="center">
        <v-row no-gutters class="mt-2">
          <v-col cols="12">
            <span class="subtitle-2">
              {{translate("getLoadedCartText")}} <br/><br/> From: {{ loadedCartTimeStamp | toLocaleDateTime(UILanguage) }}
            </span>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>{{translate("getLoadedCartTextShouldBeLoaded")}}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn icon color="success" @click="loadCartWithData">
          <v-icon> mdi-check </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="deleteCart">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { translationService } from "@/services/translationService.js";
import moment from "moment";

export default {
  name: "CustomerSelection",

  components: {},

  data: () => ({
    search: '',
    selectedCustomerId: null,
    showLoadedCartDialog: false,
    loadedCartTimeStamp: null
  }),
  computed: {
    ...mapState(["customer", "hideCustomerSelection", "availableCustomers"]),
    ...mapGetters(["isLoggedInAsUser","UILanguage", "isLoggedInAsAdmin"]),
    showDialog: {
      get() {
        //if pa_employee, ALWAYS, show this if no customer is selected! they can search a customer in the box
        if(this.isLoggedInAsAdmin){
          return this.isLoggedInAsAdmin && !this.customer;
        }

        if (this.availableCustomers.length == 0) {
          this.$store.dispatch("getAvailableCustomers");
        }
        //if only one customer, set him
        /*if (this.availableCustomers.length == 1) {
          this.setCustomer(this.availableCustomers[0]);
        } */
        return (
          this.isLoggedInAsUser &&
          !this.customer &&
          this.availableCustomers.length > 0 &&
          !this.hideCustomerSelection
        );
      },
    },
    selectedCustomer: {
      get() {
        return this.availableCustomers[this.selectedCustomerId];
      },
    },
    filteredAvailableCustomers: function(){
      if(!this.search){ return this.availableCustomers; }

      //search by id or name
      return this.availableCustomers.filter(
          x => x.customerid.toString().includes(this.search) || x.address.some(
          y => y.name.name1.toLowerCase().includes(this.search.toLowerCase()) || 
          y.name.name2.toLowerCase().includes(this.search.toLowerCase()) || 
          y.name.name3.toLowerCase().includes(this.search.toLowerCase())))
    }
  },
  filters:{
    toLocaleDateTime: function(value, lang = {locale: 'en-GB'}){ //en-GB
      return moment(value).locale(lang.locale).format('LLL')
    },
  },
  methods: {
    translate: function(field){
      return translationService.translate(field);
    },
    loadCustomer: async function(){
      if(!this.isLoggedInAsAdmin){ return; }
      await this.$store.dispatch("getCustomerById", this.search);
    },
    setCustomer: async function (customer = null) {
      await this.$store.dispatch(
        "setCustomer",
        customer || this.selectedCustomer
      );
      this.search = '';
      //get loaded, if loaded YES then get data from that //if everything ok, return here
      //get parts etc.
      const cartRes = await this.$store.dispatch("getCart", true);
      if(cartRes && cartRes.status == 200 && cartRes.data){
        this.loadedCartTimeStamp = cartRes.data.lastModified;
        this.showLoadedCartDialog = true;
        return;
      }
      await this.$store.dispatch("getPortfolio");
      await this.$store.dispatch("getPartData");
    },
    loadCartWithData: async function (){
      this.showLoadedCartDialog = false;
      await this.$store.dispatch("getCart", false);
    },
    deleteCart: async function (){
      await this.$store.dispatch("deleteCart");
      this.showLoadedCartDialog = false;
      await this.$store.dispatch("getPortfolio");
      await this.$store.dispatch("getPartData");
    }
  },
  updated: async function () {},
  watch: {
    '$store.state.availableCustomers': async function (){
      //if only one customer and no admin, set it
       if (this.availableCustomers.length == 1 && !this.isLoggedInAsAdmin) {
        await this.setCustomer(this.availableCustomers[0]);
      }
    }
  },
};
</script>

<style>
.captionSmall {
  font-size: 0.625rem !important;
  letter-spacing: 0.1666666667em !important;
}
</style>