<template>
  <v-dialog :value="showLoading" fullscreen>
    <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12">
          <v-row align="center" justify="center">
            <v-progress-circular indeterminate color="schuberthMain"></v-progress-circular>
          </v-row>
          <v-row align="center" justify="center">
            <span>{{loadingText}}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Loader',
  //props:['showLoading'],

  data: () => ({
    //
  }),
  computed:{
    ...mapState(['showLoading', "loadingText"])
  }
};
</script>
